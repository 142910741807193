<template>
  <div>
    <v-data-table
      :headers="header"
      :items="datos"
      :search="search"
      :loading="loadingData"
      :footer-props="{itemsPerPageText: 'Mostrar'}"

    >
      <template v-slot:item.id="{item}">
        <v-icon size="28" color="icono" class="pa-4" v-text="icons[item.tipo]" />
      </template>
      <template v-slot:item.tipo="{item}">
        <span
          class="primary--text font-weight-regular text-capitalize font-italic"
          :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }"
          v-text="item.tipo" />
      </template>
      <template v-slot:item.clave="{item}">
        <span
          class="primary--text font-weight-bold"
          :class="{'display': !$vuetify.breakpoint.mobile, 'subtitle-1': $vuetify.breakpoint.mobile }"
          v-text="item.clave" />
      </template>
      <template v-slot:item.valor="{item}">
        <span style="white-space: normal;" v-text="item.valor" />
      </template>
      <template v-slot:item.status="{ item }">
        <div>
          <v-chip
            class="my-2 font-weight-bold"
            :color="item.status === 1 ? 'success-alert' : 'disabled-alert' "
            small
            label
            pill
            dark
          >
            {{ item.status === 1 ? 'Activo' : 'Inactivo' }}
          </v-chip>
        </div>
      </template>
      <template v-slot:item.iconos="{item}">
        <div
          v-if="$hasPermission('administracion.bancos.editar')"
          class="d-flex flex-row justify-end"
        >
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  plain
                  icon
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="actionUser(item)"
                >
                  <v-icon
                    size="20"
                    color="blue-grey"
                    :left="$vuetify.breakpoint.xsOnly"
                    v-text="'mdi-pencil'"
                  />
                </v-btn>
              </template>
              <span>Editar Parámetro</span>
          </v-tooltip>
          <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  plain
                  icon
                  class="mr-2"
                  v-bind="attrs"
                  v-on="on"
                  @click="trashParam(item)"
                >
                  <v-icon
                    size="20"
                    color="blue-grey"
                    :left="$vuetify.breakpoint.xsOnly"
                    v-text="'mdi-trash-can-outline'"
                  />
                  <span class="mr-1" v-if="$vuetify.breakpoint.xsOnly">Eliminar</span>
                </v-btn>
              </template>
              <span>Eliminar Parámetro</span>
          </v-tooltip>
        </div>
      </template>
      <template #loading>
        <v-skeleton-loader
          type="table-tbody"
        ></v-skeleton-loader>
      </template>
      <template v-slot:no-data>
        <div class="text-center pa-4">
          <span class="blue-grey--text font-italic">No se encontraron Parémetros Registrados</span>
        </div>
      </template>
    </v-data-table>
    <material-wait
      v-model="deleting"
      icon="mdi-trash-can-outline"
      text="Eliminando parámetro"
    />
  </div>
</template>
<script>
import { deleteParam } from '@/services/params'
export default {
  name: 'ParamsData',
  props:{
    datos: {
      type: Array,
      default: () => ([])
    },
    search: String,
    loadingData: Boolean,
  },
  computed: {
    header() {
      return [
        {
          text: '',
          value: 'id',
          class: 'header-data',
          width: '50',
          sortable: false
        },
        {
          text: 'Tipo',
          align: 'start',
          value: 'tipo',
          class: 'header-data',
          width: '90',
        },
        {
          text: 'Clave',
          align: 'start',
          value: 'clave',
          class: 'header-data'
        },
        {
          text: 'Valor',
          align: 'start',
          value: 'valor',
          class: 'header-data',
          width: '40%',
        },
        {
          text: 'Estatus',
          align: 'center',
          value: 'status',
          class: 'header-data',
        },
        {
          text: this.$vuetify.breakpoint.smAndUp ? '' : 'Acciones',
          value: 'iconos',
          class: 'header-data',
          width: '30'
        },
      ]
    },
  },
  data: () => ({
    icons: {
      imagen: 'mdi-file-image-outline',
      string: 'mdi-text-box-outline',
      fecha: 'mdi-calendar-clock',
    },
    deleting: false,
  }),
  methods:{
    actionUser (item) {
      this.$emit('update', { edit: true, item: item })
    },
    async trashParam (item) {
      const confirmar = await this.$root.$confirm(
        '¿Estas Seguro?',
        'Desea eliminar el parametro '+ item.clave,
        {
          type: 'confirm',
          btnConfirmText: 'Si, Eliminar',
          btnCancelText: 'Descartar',
        }
      );
      if (confirmar) {
        try {
          this.deleting = true
          const { message } = await deleteParam({ id: item.id })
          this.$emit('procesado')
          this.$root.$showAlert(message, 'success')
        } catch (error) {
            this.$root.$showAlert(
              'Lo sentimos, hubo un error al intentar realizar esta acción en el Servidor.',
              'error',
            );
        } finally {
          this.deleting = false
        }
      }
    }
  },
}
</script>
